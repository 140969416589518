@import '@sass/app.scss';

.social-media-links__container {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.social-media-links__item {
  flex: 1;
  display: flex;
  a {
    border: 0;
    font-size: 15px;
    color: $details-text;
    display: flex;
    margin: auto;
    i {
      margin: auto 5px;
    }
    p {
      margin: auto 0px;
    }
  }
}

#social-media-icon {
  color: black;
  font-size: 18px;
  p {
    color: black;
  }
}

#social-media-icon:hover > i {
  color: $primary;
}

#social-media-icon:hover > p {
  color: $primary;
}

#social-media-briefcase {
  color: black;
  font-size: 15px;
  p {
    color: black;
  }
}
#social-media-briefcase:hover > i {
  color: $primary;
}

#social-media-briefcase:hover > p {
  color: $primary;
}
